<div class="content-wrapper container">
  <div class="card card-default">
    <div class="card-header">Create Order:</div>
    <div class="card-body">
      <div class="">
        <div class="form-group row">
          <div class="col-md-10">
            <div class="row register-form">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    [(ngModel)]="addName"
                    class="form-control"
                    placeholder="Name *"
                    value=""
                    tabindex="1"
                  />
                </div>

                <div class="form-group">
                  <label>Address</label>
                  <textarea
                    type="text"
                    [(ngModel)]="addAddress"
                    class="form-control"
                    placeholder="Address *"
                    value=""
                    tabindex="3"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label>Select Order Type**</label>
                  <select
                    class="restarant"
                    [(ngModel)]="orderCategorySelectId"
                    placeholder="Address *"
                    (change)="orderCategorySelect()"
                    tabindex="5"
                  >
                    <option class="hidden" selected="" disabled="">
                      Select
                    </option>
                    <option
                      *ngFor="let orderCategory of orderCategoryList"
                      [value]="orderCategory.id"
                    >
                      {{ orderCategory.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Select restarant</label>
                  <select
                    class="restarant"
                    [(ngModel)]="nrSelect"
                    (change)="viewList(nrSelect)"
                    tabindex="5"
                    [disabled]="
                      orderDetails.length > 0 && orderCategorySelectId
                    "
                  >
                    <option class="hidden" selected="" disabled="">
                      Select
                    </option>
                    <option
                      *ngFor="let restraurant of restraurants"
                      [value]="restraurant.id"
                      [hidden]="!restraurant.is_active || !restraurant.is_open"
                    >
                      {{ restraurant.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="addPhone"
                    maxlength="10"
                    placeholder="Phone *"
                    value=""
                    tabindex="2"
                  />
                </div>
                <div class="form-group">
                  <label>Alternate number</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="alternate"
                    maxlength="10"
                    placeholder="Alternate number"
                    value=""
                    tabindex="4"
                  />
                </div>
                <div class="form-group">
                  <label>Location</label>
                  <select
                    class="form-control"
                    [(ngModel)]="serviceLocationId"
                    tabindex="6"
                  >
                    <option class="hidden" selected="" disabled="">
                      Select Location
                    </option>
                    <option [value]="area.id" *ngFor="let area of deliveryArea">
                      {{ area.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Payment mode</label>
                  <select
                    class="form-control"
                    [(ngModel)]="paymentMethod"
                    tabindex="6"
                  >
                    <option class="hidden" selected="" disabled="">
                      Select Payment
                    </option>
                    <option>CASH</option>
                    <option>ONLINE</option>
                  </select>
                </div>
              </div>
            </div>

            <div
              class="form-group selected-items"
              *ngIf="orderDetails.length > 0"
            >
              <label class="heading">Selected Items</label>
              <table class="table">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Quantity</td>
                    <td>Price/Quantity</td>
                    <td>Remove Item</td>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let addItem of orderDetails; let in = index">
                    <td>{{ !addItem.items ? addItem.name : "" }}</td>
                    <td>
                      <div *ngIf="!addItem.items">{{ addItem.count }}</div>
                      <div *ngIf="addItem.items">
                        <div
                          class="divder-div"
                          *ngFor="let price of addItem.items"
                        >
                          <label style="width: 100%">{{ addItem.name }}</label>
                          <label style="width: 50%">
                            ({{ price.quantity }} X {{ price.type }}) X
                            {{ price.price }}</label
                          >
                          <label>= {{ price.quantity * price.price }}</label>
                        </div>
                      </div>
                    </td>
                    <td>{{ addItem.orderPrice }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-danger"
                        (click)="remove(addItem, in)"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="card-header">
                Add Comments
                <textarea
                  [(ngModel)]="comments"
                  placeholder="Add Comments"
                  class="search-f"
                >
                </textarea>
              </div>
            </div>

            <button
              class="btn btn-primary"
              (click)="placeOrder()"
              [disabled]="
                !paymentMethod ||
                !addName ||
                !paymentMethod ||
                addPhone.length != 10 ||
                !serviceLocationId ||
                !nrSelect ||
                !addAddress ||
                !totalPriceChel
              "
            >
              Place Order
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-default" *ngIf="finalMenuList.length > 0">
    <div class="card-header">
      Menu List
      <input [(ngModel)]="terms" placeholder="Search Item" class="search-f" />
    </div>

    <div class="card-body">
      <div class="menu-header">
        <div style="border-left: none">Menu Name</div>
        <div>Price</div>
        <div>Quantity</div>
      </div>

      <span *ngFor="let list of finalMenuList">
        <div class="headerMenu">{{ list.group }}</div>

        <span *ngIf="orderCategorySelectId == 1">
          <food-item
            *ngFor="let menu of list.items | search: terms:name"
            [menu]="menu"
            (valueChange)="getItem($event)"
          ></food-item>
        </span>

        <span *ngIf="orderCategorySelectId != 1">
          <other-item
            *ngFor="let menu of list.items | search: terms:name"
            [menu]="menu"
            (submitHandlerOutput)="getItem($event)"
          ></other-item>
        </span>
      </span>
    </div>
  </div>
</div>

<div class="loader-backdrop" *ngIf="isLoading">
  <div class="loader"></div>
  <h4>Loading...</h4>
</div>
