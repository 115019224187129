<span class="menu-header">
  <div [ngStyle]="{'border-left-color': menu.type == 'nv' ? 'red' : 'green' }">{{menu.name}}</div>
    <button class="btn btn-primary" *ngIf="menu.is_active == 1"  (click)="openModal(template)">Add</button>
    <strong *ngIf="menu.is_active == 0">Unavailable</strong>
</span>




<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Modal</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">


  <table class="table table-stripped">
    <thead>
     
      <tr>
        <td style="width: 140px;">Item</td>
        <td style="width: 60px;">Price</td>
        <td>Quantity</td>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of addForm.get('rows')?.controls;let index = index;">
        <td style="width: 100px;">
          <input  type="text" class="non-input" readonly [formControl]="row.get('type')" >
       </td>
       
        <td style="width: 60px;">
           <input  type="number" class="non-input" readonly [formControl]="row.get('price')">
        </td>

        <td style="width: 80px;">
          <input style="width: 100%" type="number"   [formControl]="row.get('quantity')" >
       </td>
      </tr>
    </tbody>
  </table>


  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="modalRef.hide()">close</button>

    <button type="button" class="btn btn-primary" (click)="addItems()">Add Quantites</button>
  </div>
</ng-template>