<div class="content-wrapper container">

  <div class="card card-default" *ngIf="locationArr.length>0">
    <div class="card-header"> Locations
    </div>

    <div class="card-body ">


      <table class="table table-stripped">
        <thead>

          <tr>
            <td>ID</td>
            <td>Name</td>
            <td>Fee</td>
            <td>Status</td>
            <td>View</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let location of locationArr; let index = index">
            <td>{{location.id}}</td>
            <td>{{location.name}}</td>
            <td>{{location.charge}}</td>
            <td>
              <button class="btn  btn-sm" [ngClass]="location.is_active == 1 ? 'btn-success' : 'btn-warning'"
                (click)="isActive(location, index)">{{location.is_active == 1 ? 'Active':'Inactive'}}</button>
            <td> <button class="btn btn-primary btn-sm" (click)="isEdit(location, index)">Edit</button></td>
          </tr>
          <td>
        </tbody>
      </table>
    </div>
  </div>
</div>


<div class="modal-backdrop" *ngIf="openModel"></div>
<div class="modal fade in" *ngIf="openModel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Location: {{editLocationArr.name}}</h4>
            </div>
            <div class="modal-body">

              <table class="table table-stripped">
                <thead>
        
                  <tr>
                    <td>ID</td>
                    <td>Name</td>
                    <td>Fee</td>
                    <td>Status</td>
                    <td>Update</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{editLocationArr.id}}</td>
                    <td><input class="form-control" type="text" [(ngModel)]="name" /></td>
                    <td><input class="form-control" type="number" [(ngModel)]="charge" /></td>
                    <td>
                      <button class="btn  btn-sm" [ngClass]="isActiveLocation == 1 ? 'btn-success' : 'btn-warning'"
                        (click)="isUpdateActive()">{{isActiveLocation == 1 ? 'Active':'Inactive'}}</button>
                    <td> <button class="btn btn-primary btn-sm" [disabled]="!name || charge < 0" (click)="isUpdate()">Update</button></td>
                  </tr>
                  <td>
                </tbody>
              </table>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="closeFn()">Close</button>
            </div>
        </div>
    </div>
</div>