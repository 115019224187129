<div class="content-wrapper container">
  <div class="card card-default">
    <div class="card-header">Create Misc Order:</div>
    <div class="card-body">
      <div class="">
        <div class="form-group row">
          <div class="col-md-10">
            <div class="row register-form">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    tabindex="1"
                    [(ngModel)]="addName"
                    class="form-control"
                    placeholder="Name *"
                    value=""
                  />
                </div>

                <div class="form-group">
                  <label>Address</label>
                  <textarea
                    type="text"
                    tabindex="3"
                    [(ngModel)]="addAddress"
                    class="form-control"
                    placeholder="Address *"
                    value=""
                  ></textarea>
                </div>

                <div class="form-group">
                  <label>Add Order</label>
                  <textarea
                    class="form-control"
                    tabindex="5"
                    [(ngModel)]="addOrder"
                    tabindex="5"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Phone</label>
                  <input
                    type="text"
                    tabindex="2"
                    maxlength="10"
                    class="form-control"
                    [(ngModel)]="addPhone"
                    placeholder="Phone *"
                    value=""
                  />
                </div>

                <div class="form-group">
                  <label>Alternate number</label>
                  <input
                    type="text"
                    tabindex="4"
                    maxlength="10"
                    class="form-control"
                    [(ngModel)]="alternate"
                    placeholder="Alternate number"
                    value=""
                  />
                </div>

                <div class="form-group">
                  <label>Location</label>
                  <select
                    class="form-control"
                    tabindex="6"
                    [(ngModel)]="serviceLocationId"
                  >
                    <option class="hidden" selected="" disabled="">
                      Select Location
                    </option>
                    <option [value]="area.id" *ngFor="let area of deliveryArea">
                      {{ area.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Payment mode</label>
                  <select
                    class="form-control"
                    tabindex="7"
                    [(ngModel)]="paymentMethod"
                  >
                    <option class="hidden" selected="" disabled="">
                      Select Payment
                    </option>
                    <option>CASH</option>
                    <option>ONLINE</option>
                  </select>
                </div>
              </div>
            </div>

            <button
              class="btn btn-primary"
              (click)="placeOrder()"
              [disabled]="
                !paymentMethod ||
                !addName ||
                !paymentMethod ||
                addPhone.length != 10 ||
                !serviceLocationId ||
                !addAddress ||
                !addOrder
              "
            >
              Place Order
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-backdrop" *ngIf="isLoadMoreBtn">
  <div class="loader"></div>
  <h4>Loading...</h4>
</div>
